<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Update Vendor</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-autocomplete
                v-model="form_data.category_id"
                :error-messages="form_error.category_id"
                :items="categories"
                item-text="name"
                item-value="id"
                label="Category"
                outlined
                dense
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-select
                v-model="form_data.sponsor_type"
                :error-messages="form_error.sponsor_type"
                :items="sponsor_type"
                item-text="name"
                item-value="id"
                label="Sponsor Level"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.name"
                :error-messages="form_error.name"
                label="Name"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.phone_number"
                :error-messages="form_error.phone_number"
                label="Phone number"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.company_name"
                :error-messages="form_error.company_name"
                label="Company Name"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.title"
                :error-messages="form_error.title"
                label="Title"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <vue-editor v-model="form_data.company_desc" :editorToolbar="customToolbar"> </vue-editor>
            </v-col>
          </v-row>
          <v-row>
             <v-col cols="12">
              <v-textarea
                outlined
                rows="3"
                v-model="form_data.company_address"
                label="Company Address"
              ></v-textarea>
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.email"
                :error-messages="form_error.email"
                label="Email"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6" sm="12" class="py-0">
              <v-file-input
                v-model="form_data.v_image"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Company Logo"
                prepend-icon="mdi-camera"
                label="Avatar"
                outlined
                dense
              ></v-file-input>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.social_links.facebook"
                :error-messages="form_error.facebook"
                label="Facebook Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.social_links.linkedin"
                :error-messages="form_error.linkedin"
                label="Linkedin Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.social_links.twitter"
                :error-messages="form_error.twitter"
                label="X Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.social_links.instagram"
                :error-messages="form_error.instagram"
                label="Instagram Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.social_links.youtube"
                :error-messages="form_error.youtube"
                label="Youtube Link"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-select
                v-model="form_data.booth_color"
                :error-messages="form_error.booth_color"
                :items="booth_colors"
                item-text="name"
                item-value="color"
                label="Booth Color"
                outlined
                dense
                v-if="form_data.sponsor_type==4 || form_data.sponsor_type==5 || form_data.sponsor_type==6"
              ></v-select>
            </v-col>
          </v-row>

                     <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.meta.title"
                label="Meta Title"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.meta.description"
                label="Meta Description"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4"
            color="secondary"
            @click="updateVendor"
            :loading="loading"
            :disabled="loading"
          >Update</v-btn>
        </form>
      </v-card-text>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left to="/vendor">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { mapMutations } from "vuex";

export default {
  name: "AddVendor",
  components: {
    VueEditor,
  },
  data() {
    return {
      form_data: {
        meta:{
          title:'',
          description:''
        }
      },
      form_error: {},
      loading: false,
      data: {},
      booth_colors: [
        { name : 'Gray', color : 'grey'},
        { name : 'Red', color : 'red'},
        { name : 'Red-Orange', color : 'red_orange'},
        { name : 'Orange', color : 'orange'},
        { name : 'Yellow', color : 'yellow'},
        { name : 'Yellow-Orange', color : 'yellow_orange'},
        { name : 'Yellow-Green', color : 'yellow_green'},
        { name : 'Green', color : 'green'},
        { name : 'Blue-Green', color : 'blue_green'},
        { name : 'Blue', color : 'blue'},
        { name : 'Blue-Violet', color : 'blue_violet'},
        { name : 'Violet', color : 'violet'},
        { name : 'Red-Violet', color : 'red_violet'},
      ],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],        
        [{ list: "ordered" }, { list: "bullet" }],        
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"]        
      ]
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getVendorDetails() {
      this.loading = true;
      let _self = this;
      this.$axios.get(`admin/vendor/details/${_self.$route.params.id}`)
        .then((res) => {
          _self.form_error = {};
          if (res.status) {
            let data = res.data.data;

            var doc = new DOMParser().parseFromString(
              data.company_desc,
              "text/html"
            );
            data.company_desc = ![null, undefined, "", "null", "undefined"].includes(doc.documentElement.textContent) ? doc.documentElement.textContent : null;

            for (let key in data) {
              if(key == 'category_id') {                
                const category = []
                for(let i= 0;i < data[key].length;i++){
                  category.push(data[key][i].id)
                }        
                console.log(category)        
                _self.form_data[key] = category;
              } else {
                _self.form_data[key] = data[key];
              }              
            }
          } else {
            let errors = res.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    updateVendor() {
      let _self = this;
      this.loading = true;
      
      let formData = JSON.parse(JSON.stringify(this.form_data)); //deep copying the object data

      formData.category_id = this.form_data.category_id.toString(); //converting the array to string
      formData.meta = JSON.stringify(formData.meta);
      let social_medias = [];

      for(let key of Object.keys(formData.social_links) ) {
        if(formData.social_links[key]) {
          social_medias.push({
            name: key,
            link: formData.social_links[key]
          })
        }
      }
      formData.social_medias = JSON.stringify(social_medias);

      delete formData.social_links;
      formData.profile_pic = null;

      this.$axios.put(`admin/vendor/edit/${_self.$route.params.id}`,formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.user_data = response_data.user_data;
            _self.$router.push("/vendor");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getCategoryList(){
      let _self = this;
      this.$axios.get(`admin/category/list?pagination=false`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.categories = response_data.data;
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getSponsorTypeList(){
      let _self = this;
      this.$axios.get(`sponser_type/list?type=SPONSORSHIP&pagination=false`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.sponsor_type = response_data.data;
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getSponsorTypeList();
    this.getCategoryList();
    this.getVendorDetails();
  },
};
</script>